/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useMaterialUIController, getSession } from "context";
import { useEffect, useState } from "react";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { Button, Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import { Add, Send, Sync } from "@mui/icons-material";

import "../authentication/sign-in/sign.css";
import DataTable from "examples/Tables/DataTable";
import { useAbonnements } from "layouts/tables/data/authorsTableData";
import MDButton from "components/MDButton";
import { setLayout } from "context";
import moment from "moment";
import RelanceWindow from "./components/Relancewindow";
import FinalFilter from "layouts/searchcomponent";

function Dashboard() {
  const { columns, rows } = useAbonnements();
  const [controller, dispatch] = useMaterialUIController();
  const [loaded, setLoaded] = useState(false);
  const [opened, setOpened] = useState(false);
  const [prov, setProv] = useState("");
  const { sales, tasks } = reportsLineChartData;
  const [date1, setDate] = useState(moment(null).format(""))
  const { pathname } = useLocation();
  const navigate = useNavigate();
  /*  useEffect(() => {
     setLayout(dispatch, "dashboard");
   }, [pathname]); */


  const search = (value, date, p) => {
    var regexObj = new RegExp(value + "", "i");
    var regexObj2 = new RegExp(p + "", "i");
    //alert(JSON.stringify({date,value,p}))
    if (!p) setProv("")
    setDate(date)
    if (value) {
      const runcond = (e) => {
        const condition1 =
          regexObj.test(e.client) ||
          regexObj.test(e.numero_abonnement) ||
          regexObj.test(e.phone) ||
          regexObj.test(e.fournisseur) ||
          regexObj.test(e.date_renouvellement);
        const condition2 = moment(date).diff(moment(e.date_renouvellement), "d") === 0
        const conditions3 = p && regexObj2.test(e.fournisseur)
        const condition4 = date && !/Invalid/.test(date) ? condition1 && condition2 : condition1
        return conditions3 ? conditions3 && condition4 : condition4;
      }

      return dispatch({
        type: "ABONNEMENTS",
        value: controller.abonnementso.filter((e) => {
          return (
            runcond(e)
          );
        }),
      });
    } else if (date && !/Invalid/.test(date)) {
      const runcond = (e) => {
        const condition1 = moment(date).diff(moment(e.date_renouvellement), "d") === 0
        const conditions2 = p && regexObj2.test(e.fournisseur);
        return conditions2 ? (condition1 && conditions2) : condition1
      }
      return dispatch({
        type: "ABONNEMENTS",
        value: controller.abonnementso.filter((e) => {
          return (
            runcond(e)
          );
        }),
      });
    } else if (p) {
      const runcond = (e) => {
        const conditions2 = p && regexObj2.test(e.fournisseur);
        return conditions2;
      }
      return dispatch({
        type: "ABONNEMENTS",
        value: controller.abonnementso.filter((e) => {
          return (
            runcond(e)
          );
        }),
      });

    }

    return dispatch({ type: "ABONNEMENTS", value: controller.abonnementso });
  };

  const setPartner = (name) => {
    setProv(name)
    search(name, date1, name)
  }


  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
      getSession(dispatch);
      search("", date1, prov)
    }, 200);

    return () => {
      dispatch({
        type: "ABONNEMENTS",
        value: controller.abonnementso
      })
    }
  }, []);

  return (
    <>
      {!loaded && (
        <BasicLayout image={bgImage}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={2}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                <Sync className="spin" /> Chargement...
              </MDTypography>
            </MDBox>
          </Card>
        </BasicLayout>
      )}
      {!controller.session && loaded && <Navigate to="/authentication/sign-in" />}
      {controller.session && loaded && (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox
            py={3}
            mb={2}

          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox
                  mb={1.5} onClick={() => setPartner(controller.clients?.abons?.at(0)?.fournisseur)}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon={controller.clients?.abons?.at(0)?.logo ? { src: controller.baseurl + controller.clients?.abons?.at(0)?.logo } : "weekend"}
                    variant="gradient"
                    bgColor="info"
                    title={controller.clients?.abons?.at(0)?.fournisseur || "..."}
                    count={controller.clients?.abons?.at(0)?.nbclients || 0}
                  /* percentage={{
                    color: "success",
                    amount: "+55%",
                    label: "than lask week",
                  }} */
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5} onClick={() => setPartner(controller.clients?.abons?.at(1)?.fournisseur)}>
                  <ComplexStatisticsCard
                    icon={controller.clients?.abons?.at(1)?.logo ? { src: controller.baseurl + controller.clients?.abons?.at(1)?.logo } : "leaderboard"}
                    title={controller.clients?.abons?.at(1)?.fournisseur || "..."}
                    count={controller.clients?.abons?.at(1)?.nbclients || 0}
                  /*                     percentage={{
                                        color: "success",
                                        amount: "+3%",
                                        label: "than last month",
                                      }} */
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5} onClick={() => setPartner(controller.clients?.abons?.at(2)?.fournisseur)}>
                  <ComplexStatisticsCard
                    color="success"
                    icon={controller.clients?.abons?.at(2)?.logo ? { src: controller.baseurl + controller.clients?.abons?.at(2)?.logo } : "store"}
                    title={controller.clients?.abons?.at(2)?.fournisseur || "..."}
                    count={controller.clients?.abons?.at(2)?.nbclients || 0}
                  /*  percentage={{
                     color: "success",
                     amount: "+1%",
                     label: "than yesterday",
                   }} */
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="primary"
                    icon="person_add"
                    title={controller.clients?.clients?.at(0)?.compagnie || "..."}
                    count={controller.clients?.clients?.at(0)?.nbclients || 0}
                  /*  percentage={{
                     color: "success",
                     amount: "",
                     label: "Just updated",
                   }} */
                  />
                </MDBox>
              </Grid>
            </Grid>

            <MDBox mt={4.5}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDTypography variant="h6" color="white">
                        Abonnements
                      </MDTypography>
                      <MDButton
                        component="a"
                        onClick={() => navigate("/addAbon")}
                        href="#"
                        variant="gradient"
                        color="dark"
                      >
                        <Add />
                        &nbsp;Ajouter
                      </MDButton>
                    </MDBox> <MDBox pt={3}>
                     {controller.abonnementso.length > 0 && <FinalFilter data={rows} provider={prov} columns={columns} rows={rows}
                        searchText="Rechercher client, N° Abonnement, Téléphone"
                        entriesPerPage={20}
                        setFilter={search}
                        canSearch
                        needDate />}
                         {controller.abonnementso.length === 0 && (
                        
                          <MDBox mx={2} mt={2} p={2} mb={2} textAlign="center">
                            <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
                              <Sync className="spin" /> Chargement...
                            </MDTypography>
                          </MDBox> 
                      )}
                    </MDBox>
                    {/*  <MDBox pt={3}>
                      {controller.abonnementso.length > 0 && (
                        <DataTable
                          table={{ columns, rows }}
                          isSorted={false}
                          canSearch
                          needDate
                          sendDate={console.log}
                          provider={prov}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          noEndBorder
                          searchText="Rechercher client, N° Abonnement, Téléphone"
                          setFilter={search}
                          logKey={(e) => console.log(controller.abonnements[e])}
                        />
                      )}
                      {controller.abonnementso.length === 0 && (
                        <Card>
                          <MDBox mx={2} mt={2} p={2} mb={2} textAlign="center">
                            <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
                              <Sync className="spin" /> Chargement...
                            </MDTypography>
                          </MDBox>
                        </Card>
                      )}
                    </MDBox> */}
                    <MDBox mt={4} mb={1} mx={3}>
                      <MDButton
                        disabled={controller.loading}
                        onClick={() => setOpened(true)}
                        variant="gradient"
                        color="info"
                        fullWidth
                      >
                        Relancer
                      </MDButton>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>

          </MDBox>
          <RelanceWindow open={opened} setOpen={setOpened} />
          <Footer />
        </DashboardLayout >
      )
      }
    </>
  );
}

export default Dashboard;
