/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

import xhr from "../../../context/requests";
import MDSnackbar from "components/MDSnackbar";
import { CalendarMonth, Timelapse } from "@mui/icons-material";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [candelete, setDelete] = useState(false);
  const [message, setMessage] = useState({ color: "info", icon: "info", open: false, message: "" });
  const route = useLocation().pathname.split("/").slice(1);
  const navigate = useNavigate();

  const renderMessage = (message, color, icon) =>
    setMessage({
      message,
      color,
      icon,
      open: true,
    });

  const closeInfoSB = () =>
    setMessage({
      ...message,
      open: false,
    });

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const findIndexFn = (arr, id) => {
    //console.log(arr);
    return arr.findIndex((e) => e.id === Number(id));
  };


  const handleDeletion = async () => {
    if (!confirm("Supprimmer ce contenu?")) return;
    let httReq = new xhr("https://gestion.mobitransfert.com", controller.session.data[0].token);
    let id, url, togo, index, garr, reloadfn;
    switch (route[0]) {
      case "addbouquet":
        ({ id } = controller.bouquet);
        url = "/gestion/delete_bouquet";
        togo = "/bouquets";
        index = findIndexFn(controller.bouquets, id);
        garr = controller.bouquets;
        reloadfn = () => {
          garr.splice(index, 1);
          dispatch({ type: "BOUQUETS", value: garr });
        };
        break;
      case "editabon":
        ({ id } = controller.abonnement);
        url = "/gestion/delete_abon";
        togo = "/";
        index = findIndexFn(controller.abonnementso, id);
        garr = controller.abonnementso;
        reloadfn = () => {
          garr.splice(index, 1);
          dispatch({ type: "ABONNEMENTSO", value: garr });
          dispatch({ type: "ABONNEMENTS", value: garr });
        };
        break;
      case "addClient":
        ({ id } = controller.client);
        url = "/gestion/delete_client";
        togo = "/clients";
        index = findIndexFn(controller.clientsDb, id);
        garr = controller.clientsDb;
        reloadfn = () => {
          garr.splice(index, 1);
          dispatch({ type: "CLIENTS", value: garr });
        };
        break;
    }
    dispatch({ type: "LOADING", value: true });
    try {
      if (id) await httReq.addNode({ id }, url);
      dispatch({ type: "LOADING", value: false });
      reloadfn();
      navigate(togo);
    } catch (e) {
      renderMessage(e.message || e || "Erreur Reseau", "warning", "warning");
      dispatch({ type: "LOADING", value: false });
    }
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  useEffect(() => {
    if (controller.bouquet?.id || controller.abonnement?.id || controller.client?.id) {
      console.log(
        controller.bouquet?.id,
        controller.abonnement?.id,
        controller.client?.id,
        route[0]
      );
      let id;
      switch (route[0]) {
        case "addbouquet":
          id = controller.bouquet?.id;
          break;
        case "editabon":
          id = controller.abonnement?.id;
          break;
        case "addClient":
          id = controller.client?.id;
          break;
      }
      setDelete(id);
    }
  }, [controller.bouquet, controller.abonnement, controller.client]);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/*  <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
            <MDBox color={light ? "white" : "inherit"}>
              <Link to="/profile">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {candelete && (
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={handleDeletion}
                >
                  <Icon sx={iconsStyle}>delete</Icon>
                </IconButton>
              )}
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>menu</Icon>
              </IconButton> */}
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      <MDSnackbar
        color={message.color}
        icon={message.icon}
        title="Mobigestion"
        content={message.message}
        dateTime="Maintenant"
        open={message.open}
        bgWhite={true}
        onClose={closeInfoSB}
        close={closeInfoSB}
      />
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
