/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { Author, Job, useClients } from "./data/authorsTableData";
import { useMaterialUIController } from "context";
import { useEffect, useState } from "react";
import moment from "moment";
import { Add, Edit } from "@mui/icons-material";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import { setLayout } from "context";
import { getSession, setClient } from "context";
import { setBouquet } from "context";
import FinalFilter from "layouts/searchcomponent";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

function Clients() {
  const [controller, dispatch] = useMaterialUIController();
  const [clients, SetClients] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getSession(dispatch);
  }, []);

  const search = (value) => {
    var regexObj = new RegExp(value + "", "i");
    if (value)
      return SetClients(
        clients.filter((e) => {
          return regexObj.test(e.nom) || regexObj.test(e.phone);
        })
      );
    return SetClients(controller.clientsDb);
  };

  useEffect(() => {
    let updateClients = () => {
      if (controller.clientsDb) {
        SetClients(controller.clientsDb);
      }
    };
    updateClients();
    return () => {
      updateClients = () => {};
    };
  }, [controller.clientsDb]);

  const tblsData = {
    columns: [
      { Header: "Client", name: "Client",accessor: "client",selector: (row) => row.client, width: "35%", align: "left" },
      { Header: "Abonnements", name: "Abonnements", accessor: "abon",selector: (row) => row.abon, align: "center" },
      { Header: "Date de creation", name: "Date de creation", accessor: "created",selector: (row) => row.created, align: "left" },
      { Header: "modifier", name: "modifier", accessor: "edit",selector: (row) => row.edit, align: "center" },
    ],
    rows: clients.map((e) => ({
      id: e.id,
      client: <Author /* image={team2} */ name={e.nom} email={`${e.phone}`} phone="" />,
      abon: <MDBadge badgeContent={`${e.nbabo}`} color="info" variant="gradient" size="sm" />,
      created: (
        <MDTypography
          component="strong"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {moment(e.created).format("DD/MM/YYYY")}
        </MDTypography>
      ),
      edit: (
        <MDTypography
          co
          component="a"
          href="#"
          onClick={() => {
            setClient(dispatch, e);
            navigate("/addClient");
          }}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <Edit />
        </MDTypography>
      ),
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Clients
                </MDTypography>
                <MDButton
                  component="a"
                  href="#"
                  onClick={() => navigate("/addClient")}
                  variant="gradient"
                  color="dark"
                >
                  <Add />
                  &nbsp;Ajouter
                </MDButton>
              </MDBox>
              <MDBox pt={1}>
              <FinalFilter data={tblsData.rows}  columns={tblsData.columns} rows={tblsData.rows}
                        searchText="Rechercher client, Téléphone"
                        entriesPerPage={20}
                        setFilter={search}
                        canSearch
                       />
                {/* <DataTable
                  table={tblsData}
                  isSorted={false}
                  canSearch
                  entriesPerPage={false}
                  showTotalEntries={true}
                  noEndBorder
                  searchText="Rechercher client, Téléphone"
                  setFilter={search}
                  logKey={(e) => console.log(clients[e])}
                /> */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export function Bouquets() {
  const [controller, dispatch] = useMaterialUIController();
  const [clients, SetClients] = useState([]);
  const navigate = useNavigate();
  const [prov, setProv] = useState("");

  useEffect(() => {
    getSession(dispatch);
  }, []);

  const search = (value,date,p) => {
    var regexObj = new RegExp(value + "", "i");
    if (!p) setProv("")
    if (value)
      return SetClients(
        clients.filter((e) => {
          return regexObj.test(e.nom) || regexObj.test(e.fournisseur);
        })
      );
    return SetClients(controller.bouquets);
  };
  const setPartner = (name) => {
    setProv(name)
    search(name, null, name)
  }
  useEffect(() => {
    let updateClients = () => {
      if (controller.bouquets) {
        SetClients(controller.bouquets);
      }
    };
    updateClients();
    return () => {
      updateClients = () => {};
    };
  }, [controller.bouquets]);

  const tblsData = {
    columns: [
      { Header: "Bouquet/Forfait", accessor: "client",selector: (row) => row.client, width: "35%", align: "left" },
      { Header: "Fournisseur", accessor: "fournisseur",selector: (row) => row.fournisseur, align: "center" },
      { Header: "Prix", accessor: "prix",selector: (row) => row.prix, align: "left" },
      { Header: "Duree", accessor: "duration",selector: (row) => row.duration, align: "center" },
      { Header: "modifier", accessor: "edit",selector: (row) => row.edit, align: "center" },
    ],
    rows: clients.map((e) => ({
      id: e.id,
      client: <Author /* image={team2} */ name={e.nom} email="" phone="" />,
      fournisseur: (
        <MDTypography
          component="strong"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {e.fournisseur}
        </MDTypography>
      ),
      prix: (
        <MDTypography
          component="strong"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {e.prix}
        </MDTypography>
      ),
      duration: (
        <MDTypography
          component="strong"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {e.duration}
        </MDTypography>
      ),
      edit: (
        <MDTypography
          component="a"
          onClick={() => {
            setBouquet(dispatch, e);
            navigate("/addbouquet");
          }}
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <Edit />
        </MDTypography>
      ),
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
      <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox
                  mb={1.5} onClick={() => setPartner(controller.clients?.abons?.at(0)?.fournisseur)}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon={controller.clients?.abons?.at(0)?.logo ? { src: controller.baseurl + controller.clients?.abons?.at(0)?.logo } : "weekend"}
                    variant="gradient"
                    bgColor="info"
                    title={controller.clients?.abons?.at(0)?.fournisseur || "..."}
                    count={controller.clients?.abons?.at(0)?.nbclients || 0}
                  /* percentage={{
                    color: "success",
                    amount: "+55%",
                    label: "than lask week",
                  }} */
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5} onClick={() => setPartner(controller.clients?.abons?.at(1)?.fournisseur)}>
                  <ComplexStatisticsCard
                    icon={controller.clients?.abons?.at(1)?.logo ? { src: controller.baseurl + controller.clients?.abons?.at(1)?.logo } : "leaderboard"}
                    title={controller.clients?.abons?.at(1)?.fournisseur || "..."}
                    count={controller.clients?.abons?.at(1)?.nbclients || 0}
                  /*                     percentage={{
                                        color: "success",
                                        amount: "+3%",
                                        label: "than last month",
                                      }} */
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5} onClick={() => setPartner(controller.clients?.abons?.at(2)?.fournisseur)}>
                  <ComplexStatisticsCard
                    color="success"
                    icon={controller.clients?.abons?.at(2)?.logo ? { src: controller.baseurl + controller.clients?.abons?.at(2)?.logo } : "store"}
                    title={controller.clients?.abons?.at(2)?.fournisseur || "..."}
                    count={controller.clients?.abons?.at(2)?.nbclients || 0}
                  /*  percentage={{
                     color: "success",
                     amount: "+1%",
                     label: "than yesterday",
                   }} */
                  />
                </MDBox>
              </Grid> 
            </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                //mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Bouquets
                </MDTypography>
                <MDButton
                  component="a"
                  hraf="#"
                  onClick={() => navigate("/addbouquet")}
                  variant="gradient"
                  color="dark"
                >
                  <Add />
                  &nbsp;Ajouter
                </MDButton>
              </MDBox>
              <MDBox pt={1}>
              <FinalFilter data={tblsData.rows} provider={prov}  columns={tblsData.columns} rows={tblsData.rows}
                        searchText="Rechercher bouquet, forfait, fournisseur"
                        entriesPerPage={20}
                        setFilter={search}
                        canSearch
                       />
                {/* <DataTable
                  table={tblsData}
                  isSorted={false}
                  canSearch
                  entriesPerPage={false}
                  showTotalEntries={true}
                  noEndBorder
                  searchText="Rechercher bouquet, forfait, fournisseur"
                  setFilter={search}
                  logKey={(e) => console.log(clients[e])}
                /> */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export function Employees() {
  const [controller, dispatch] = useMaterialUIController();
  const [clients, SetClients] = useState([]);

  const search = (value) => {
    var regexObj = new RegExp(value + "", "i");
    if (value)
      return SetClients(
        clients.filter((e) => {
          return regexObj.test(e.nom) || regexObj.test(e.prenom);
        })
      );
    return SetClients(controller.employers);
  };

  useEffect(() => {
    let updateClients = () => {
      if (controller.employers) {
        SetClients(controller.employers);
      }
    };
    updateClients();
    return () => {
      updateClients = () => {};
    };
  }, [controller.employers]);

  const tblsData = {
    columns: [
      { Header: "Nom", accessor: "client", width: "35%", align: "left" },
      { Header: "Role", accessor: "fournisseur", align: "center" },
      { Header: "modifier", accessor: "edit", align: "center" },
    ],
    rows: clients.map((e) => ({
      id: e.id,
      client: (
        <Author
          /* image={team2} */ name={[e.nom, e.prenom].join(" ")}
          email={e.email}
          phone={e.phone}
        />
      ),
      fournisseur: (
        <MDTypography
          component="strong"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {e.role}
        </MDTypography>
      ),
      edit: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          <Edit />
        </MDTypography>
      ),
    })),
  };

  useEffect(() => {
    getSession(dispatch);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Personnel
                </MDTypography>
                <MDButton variant="gradient" color="dark">
                  <Add />
                  &nbsp;add new card
                </MDButton>
              </MDBox>
              <MDBox pt={1}>
                <DataTable
                  table={tblsData}
                  isSorted={false}
                  canSearch
                  entriesPerPage={false}
                  showTotalEntries={true}
                  noEndBorder
                  searchText="Rechercher bouquet, forfait, fournisseur"
                  setFilter={search}
                  logKey={(e) => console.log(clients[e])}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Clients;
