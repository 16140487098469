/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useLayoutEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import xhr from "context/requests";
import { useMaterialUIController } from "context";
import { placeholder, done } from "assets/base64";
import { getSession } from "context";
import { checkQr } from "context";
import moment from "moment";
import { AirplanemodeInactive, CalendarMonth, Check, Info } from "@mui/icons-material";
import PaymentForm from "layouts/billing/components/PaymentForm";
import Countdown, { zeroPad } from "react-countdown";
import { Box, Button, CircularProgress, Fab, Fade, Paper, Stack, Typography, Unstable_TrapFocus } from "@mui/material";
import { green } from "@mui/material/colors";
import { checkCoolendy } from "context";
moment.locale("fr-FR")

function WhatsappN() {
  const [controller, dispatch] = useMaterialUIController();
  const [image, setImage] = useState(placeholder);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState({ color: "info", icon: "info", open: false, message: "" });
  const [bannerOpen, setBannerOpen] = useState(false);
  const [checking, setChecking] = useState()

  const getStatus = async (extId) => {
    setBannerOpen(true)
    const httReq = new xhr("https://gestion.mobitransfert.com", controller.session.data[0].token);
    dispatch({ type: 'LOADING', value: true })
    let result = await httReq.addNode({ extId }, "/gestion/renew_status")
    if (typeof result === "string") result = JSON.parse(result)
    if (result.message) setChecking(result.message)
    if (/progress|pending/.test(result.message?.toLowerCase())) {
      setTimeout(() => getStatus(extId), 5000)
    } else if (/fail/.test(result.message?.toLowerCase())) {
      dispatch({ type: 'LOADING', value: false })
      setMessage({ color: "error", icon: "warning", open: true, message: 'le paiement a echoué' })
      setBannerOpen(false)
    } else if (/succes/.test(result.message?.toLowerCase())) {
      dispatch({ type: 'LOADING', value: false })
      setSuccess(true)
      setChecking('Votre paiement est réussi, vous allez recevoir une facture par mail et/ou par whatsapp. fermer et cliquer sur le service a nouveau')
      localStorage.removeItem('whatoken')
      setTimeout(async () => {
       await checkCoolendy(httReq, dispatch)
       checkQr(httReq, dispatch)
      }, 1000)

    } else {
      setTimeout(() => getStatus(extId), 5000)
    }
  }


  const closeBanner = () => {
    setBannerOpen(false);
  };

  useEffect(() => {
    getSession(dispatch);
  }, []);

  useEffect(() => {
    setImage(controller.qrcode);
  }, [controller.qrcode]);

  useEffect(() => {
    if (controller.session) {
      const httReq = new xhr("https://gestion.mobitransfert.com", controller.session.data[0].token);
      checkQr(httReq, dispatch);
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
    dispatch({ type: 'LOADING', value: false })
  };

  const closeInfoSB = () =>
    setMessage({
      ...message,
      open: false,
    });

  const renderer = () => (
    <MDBox p={3} px={2} display="flex" alignItems="center" justifyContent="center">
      <AirplanemodeInactive fontSize="14" mx={2} />
      <MDTypography component="em" size={11} sx={{ fontSize: 14, mx: 2 }}>
        Inactif
      </MDTypography>
      <MDButton variant="gradient" color="success" onClick={handleClickOpen} sx={{ mx: 2 }}>
        Activer
      </MDButton>
    </MDBox>
  );

  const renderer2 = ({ days, hours, minutes, seconds }) => (
    <MDBox p={3} px={2} display="flex" alignItems="center" justifyContent="center">
      <CalendarMonth fontSize="14" mx={2} />
      <MDTypography component="em" size={11} sx={{ fontSize: 14, mx: 2 }}>
        Expire dans {days}Jour{days > 1 ? "s" : ""} {zeroPad(hours)}h {zeroPad(minutes)}min {zeroPad(seconds)}s
      </MDTypography>
      <MDButton variant="gradient" color="success" onClick={handleClickOpen} sx={{ mx: 2 }}>
        Renouveller
      </MDButton>
    </MDBox>

  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Whatsapp
                </MDTypography>
                <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
                  Statut des notifications
                </MDTypography>
              </MDBox>
              <MDBox pt={2} px={2}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6} mx={"auto"} mb={2}>
                    <img
                      src={image}
                      style={{ width: "100%", height: "auto", display: "flex" }}
                    />
                  </Grid>
                </Grid>
                <Unstable_TrapFocus open disableAutoFocus disableEnforceFocus>
                  <Fade appear={true} in={bannerOpen}>
                    <Paper
                      role="dialog"
                      aria-modal="false"
                      aria-label="Cookie banner"
                      square
                      variant="outlined"
                      tabIndex={-1}
                      sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        m: 0,
                        p: 2,
                        zIndex: 100000,
                        borderWidth: 0,
                        borderTopWidth: 1,
                      }}
                    >
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        justifyContent="space-between"
                        gap={2}
                      >
                        <Box
                          sx={{
                            flexShrink: 1,
                            alignSelf: { xs: 'flex-start', sm: 'center' },
                          }}
                        >
                          <Typography fontWeight="bold">Progression du paiement</Typography>
                          <Typography variant="body">
                            {checking}
                          </Typography>
                        </Box>
                        <Stack
                          gap={2}
                          direction={{
                            xs: 'row-reverse',
                            sm: 'row',
                          }}
                          sx={{
                            flexShrink: 0,
                            alignSelf: { xs: 'flex-end', sm: 'center' },
                          }}
                        >
                          {success && <Fab
                            aria-label="save"
                            color="primary" 
                            sx={{ bgcolor: green[500],fontWeight:'bold' }}
                            onClick={closeBanner}
                          >
                            <Check fontSize="40"/>
                          </Fab>}
                          {controller.loading && (
                            <CircularProgress
                              size={68}
                              sx={{
                                color: green[500],
                              }}
                            />
                          )}
                          {/* <Button size="small" onClick={closeBanner}>
                            Reject all
                          </Button> */}
                        </Stack>
                      </Stack>
                    </Paper>
                  </Fade>
                </Unstable_TrapFocus>
              </MDBox>
              {controller.whatoken && <Countdown renderer={renderer2} date={Date.now() + moment(jwtDecode(controller.whatoken).exp * 1000).diff(moment(), "milliseconds")} />}
              {!controller.whatoken && renderer()}

            </Card>
          </Grid>
          {/* 
          <Grid item xs={12} lg={8}>
            <Card> 
              <MDBox p={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton variant="gradient" color="success" onClick={openSuccessSB} fullWidth>
                      success notification
                    </MDButton>
                    {renderSuccessSB}
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton variant="gradient" color="info" onClick={openInfoSB} fullWidth>
                      info notification
                    </MDButton>
                    {renderInfoSB}
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton variant="gradient" color="warning" onClick={openWarningSB} fullWidth>
                      warning notification
                    </MDButton>
                    {renderWarningSB}
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton variant="gradient" color="error" onClick={openErrorSB} fullWidth>
                      error notification
                    </MDButton>
                    {renderErrorSB}
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid> */}
        </Grid>
      </MDBox>
      <Footer />
      <PaymentForm open={open} setOpen={setOpen} setError={setMessage} getStatus={getStatus} setChecking={setChecking} />
      <MDSnackbar
        color={message.color}
        icon={message.icon}
        title="Mobigestion"
        content={message.message}
        dateTime="Maintenant"
        open={message.open}
        bgWhite={true}
        onClose={closeInfoSB}
        close={closeInfoSB}
      />
    </DashboardLayout>
  );
}

export default WhatsappN;
