import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Card, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useMaterialUIController } from 'context';
import MDBox from 'components/MDBox';

import xhr from 'context/requests';

export default function PaymentForm({ open, setOpen, setError, getStatus,setChecking }) {
    const [controller, dispatch] = useMaterialUIController();
    const [payerInfos, setPayerInfo] = React.useState({ payerPhone: "", qty: 1, pid: null, email: "" })


    const formatPrice = (price) => {
        let USDollar = new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'XAF',
        });

        return USDollar.format(Number(price))
    }

    const pay = async () => {
        const httReq = new xhr("https://gestion.mobitransfert.com", controller.session.data[0].token);
        dispatch({ type: 'LOADING', value: true })
        let res = await httReq.addNode(payerInfos, "/gestion/renew")
        const t = typeof res;
        try { res = JSON.parse(JSON.parse(res)); } catch (e) { res = JSON.parse(res); }
        dispatch({ type: 'LOADING', value: false })
        console.log(res, typeof (res))
        if (res.error) {
            throw Error(res.error) 
        }
        setChecking('Votre paiement a ete enregistré, merci de valider sur votre mobile en entrant votre code pin ou #150*50# pour OM ou *126# pour Momo')
        getStatus(res.extId)
        handleClose()
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleChanges = (e) => {
        setPayerInfo({
            ...payerInfos,
            [e.target.name]: e.target.value,
        });
    };

    React.useEffect(() => {
        if (controller.session?.data?.at(0)?.email) setPayerInfo({
            ...payerInfos,
            "email": controller.session?.data?.at(0)?.email,
        });
    }, [controller.session?.data?.at(0)?.email])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: async (event) => {
                    event.preventDefault();
                    try {
                        await pay()
                        //handleClose()
                    } catch (e) {
                        setError({ color: "error", icon: "warning", open: true, message: e.message })
                        dispatch({ type: 'LOADING', value: false })
                    }

                },
            }}
        >
            <DialogTitle>Renouveler votre abonnement</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Ce formulaire vous permet de payer votre abonnement whatsapp pour une periode de 1, 7 et 30jours
                </DialogContentText>
                <MDBox mb={2} mt={2} component="form" role="form">
                    <Grid container spacing={6} direction={"column"}>
                        <Grid item xs={12} md={12}>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} >
                                <InputLabel id="categori">Choisir un forfait</InputLabel>
                                <Select
                                    name="pid"
                                    value={payerInfos.pid}
                                    onChange={handleChanges}
                                    required
                                    label="Choisir un forfait"
                                >
                                    <MenuItem value="">
                                        <em>Choisir</em>
                                    </MenuItem>
                                    {controller.packages.map(e => {
                                        return <MenuItem value={e.id} key={e.id}>{`${e.name} (${formatPrice(e.price)})`}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <Grid item xs={12} md={12}>
                                <FormControl sx={{ m: 1, minWidth: 120 }} >
                                    <TextField
                                        variant="filled"
                                        label="Numero du payeur"
                                        type="tel"
                                        name='payerPhone'
                                        onChange={handleChanges}
                                        value={payerInfos.payerPhone}
                                        calendarAriaLabel="date d'activation"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDBox>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={controller.loading}>Annuler</Button>
                <Button type="submit" disabled={controller.loading || (!payerInfos.payerPhone & !payerInfos.pid)}>Payer</Button>
            </DialogActions>
        </Dialog>
    );
}
