/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useMaterialUIController } from "context";
import moment from "moment";
import { Edit } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const Author = ({ image, name, email, phone }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    {image && <MDAvatar src={image} name={name} size="sm" />}
    <MDBox ml={0} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption" display="flex">
        {email}
      </MDTypography>
      <MDTypography variant="caption" display="flex">
        {phone}
      </MDTypography>
    </MDBox>
  </MDBox>
);

export const Job = ({ title, description }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
    <MDTypography variant="caption">{description}</MDTypography>
  </MDBox>
);

export function useAbonnements() {
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  return {
    columns: [
      { name: "Client", accessor: "client",selector: (row) => row.client, width: "35%", align: "left" },
      { name: "Fournisseur", accessor: "fournisseur",selector: (row) => row.fournisseur, align: "left" },
      { name: "Statut", accessor: "statut",selector: (row) => row.statut, align: "center" },
      { name: "Activé le", accessor: "activated",selector: (row) => row.activated, align: "center" },
      { name: "Expire le", accessor: "expire",selector: (row) => row.expire, align: "center" },
      { name: "Modifier", accessor: "edit",selector: (row) => row.edit, align: "center" },
    ],
    rows: controller.abonnements.map((e) => ({
      id: e.id,
      client: (
        <Author
          /* image={team2} */ name={e.client}
          email={`${e.nom} ( ${e.numero_abonnement} )`}
          phone={`${e.phone}`}
        />
      ),
      fournisseur: <Job title={e.fournisseur} description={e.bouquet} />,
      statut: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={`restant ${
              moment(e.date_renouvellement).diff(moment(), "d") > 0
                ? moment(e.date_renouvellement).diff(moment(), "d")
                : 0
            } jours`}
            color={
              moment(e.date_renouvellement).diff(moment(), "d") >= 14
                ? "success"
                : moment(e.date_renouvellement).diff(moment(), "d") >= 7
                ? "warning"
                : "error"
            }
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      activated: (
        <MDTypography
          component="strong"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {moment(e.date_edition).format("DD/MM/YYYY")}
        </MDTypography>
      ),
      expire: (
        <MDTypography
          component="strong"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {moment(e.date_renouvellement).format("DD/MM/YYYY")}
        </MDTypography>
      ),
      edit: (
        <MDTypography
          onClick={(event) => {
            dispatch({ type: "ABONNEMENT", value: e });
            navigate(`/editabon`);
          }}
          component="a"
          href={`#`}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <Edit />
        </MDTypography>
      ),
    })),
  };
}

export function useSms() {
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  return {
    columns: [
      { Header: "Contenu",name: "Contenu", accessor: "body",selector: (row) => row.body, width: "75%", align: "left" },
     // { Header: "Categorie", accessor: "categori", align: "left" },
      { Header: "Modifier",name: "Modifier", accessor: "edit",selector: (row) => row.edit, align: "center" },
    ],
    rows: controller.sms.map((e) => ({
      id: e.id,
      body: <Job title={e.categori} description={e.body} />,
      edit: (
        <MDTypography
          onClick={(event) => {
            dispatch({ type: "SMSI", value: e });
           // navigate(`/editabon`);
          }}
          component="a"
          href={`#`}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <Edit />
        </MDTypography>
      ),
    })),
  };
}
export function useClients() {
  const [controller, dispatch] = useMaterialUIController();
  const [client, SetClients] = useState();
  return {
    columns: [
      { Header: "Client", accessor: "client", width: "35%", align: "left" },
      { Header: "Abonnements", accessor: "abon", align: "left" },
      { Header: "Date de creation", accessor: "created", align: "left" },
      { Header: "modifier", accessor: "edit", align: "center" },
    ],
    rows: controller.clientsDb.map((e) => ({
      id: e.id,
      client: <Author /* image={team2} */ name={e.nom} email={`${e.phone}`} phone="" />,
      abon: <Job title={e.fournisseur} description={e.bouquet} />,
      created: (
        <MDTypography
          component="strong"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {moment(e.created).format("DD/MM/YYYY")}
        </MDTypography>
      ),
      edit: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          <Edit />
        </MDTypography>
      ),
    })),
  };
}

export default function data() {
  return {
    columns: [
      { Header: "author", accessor: "author", width: "45%", align: "left" },
      { Header: "function", accessor: "function", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "employed", accessor: "employed", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: [
      {
        author: <Author /* image={team2} */ name="John Michael" email="john@creative-tim.com" />,
        function: <Job title="Manager" description="Organization" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/18
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        author: <Author image={team3} name="Alexa Liras" email="alexa@creative-tim.com" />,
        function: <Job title="Programator" description="Developer" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="offline" color="dark" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            11/01/19
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        author: <Author image={team4} name="Laurent Perrier" email="laurent@creative-tim.com" />,
        function: <Job title="Executive" description="Projects" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            19/09/17
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        author: <Author image={team3} name="Michael Levi" email="michael@creative-tim.com" />,
        function: <Job title="Programator" description="Developer" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            24/12/08
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        author: <Author image={team3} name="Richard Gran" email="richard@creative-tim.com" />,
        function: <Job title="Manager" description="Executive" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="offline" color="dark" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            04/10/21
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        author: <Author image={team4} name="Miriam Eric" email="miriam@creative-tim.com" />,
        function: <Job title="Programator" description="Developer" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="offline" color="dark" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            14/09/20
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
    ],
  };
}
