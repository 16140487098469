import { Clear } from "@mui/icons-material";
import { Chip, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import DataTable, { TableProps } from "react-data-table-component";



const FilterComponent = ({
    searchText,
    provider,
    setFilter,
    canSearch,
    needDate
}, props) => {
    const [search, setSearch] = React.useState("");
    const [date, setDate] = React.useState("");
    return (
        <>
            {canSearch && (
                <MDBox display="flex" width="100%" justifyContent="flex-start" alignItems="center" backgroundColor={"#eeeeee"} py={3} px={0}>
                    <Grid container spacing={2} >
                        {needDate && <Grid item xs={12} md={4}>
                            <MDBox width="auto" ml="auto" sm={12} display={"flex"} alignItems={"center"}>
                                <MDTypography mx={2} variant="caption" color="secondary">
                                    Echeance
                                </MDTypography>
                                <TextField
                                    type="date"
                                    fullWidth                                    
                                    size="small"
                                    variant="filled"
                                    onChange={(e) => {
                                        setDate(moment(e.target.value).format("YYYY-MM-DD"))
                                        setFilter(search, moment(e.target.value).format("YYYY-MM-DD"), provider);
                                        //sendDate(moment(e.target.value).format("YYYY-MM-DD"))                    
                                        //setFilter(search);
                                    }}
                                    value={date}
                                    calendarAriaLabel="date d'activation"
                                />
                            </MDBox></Grid>}
                        <Grid item xs={12} md={6}>
                            <MDBox width="auto" ml="auto" sm={12} display={"flex"} alignItems={"center"}>
                                <MDInput
                                    placeholder={searchText || "Rechercher"}
                                    value={search}
                                    size="small"
                                    fullWidth
                                    variant="filled"
                                    onChange={({ currentTarget }) => {
                                        setSearch(currentTarget.value);
                                        setFilter(currentTarget.value, date, provider);
                                        //setFilter(currentTarget.value);
                                        //onSearchChange(currentTarget.value);
                                    }}

                                />
                                {
                                    provider && <Chip
                                        icon={"close"}
                                        sx={{ ml: 2 }}
                                        label={provider}
                                        onDelete={() => setFilter(search, date, "")}
                                    />
                                }
                            </MDBox>
                        </Grid>
                    </Grid>

                </MDBox>
            )}
        </>
    )
};




const FinalFilter = (props) => {
    const [filterText, setFilterText] = React.useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );

    // const filteredItems = data.filter(
    //   item => item.name && item.name.includes(filterText)
    // ); 
    const matcher = (string, container) => {
        const regex = new RegExp(string, 'gi');
        return container.match(regex) !== null ? container.match(regex)?.length : container.match(regex)
    }
    //const filteredItems = props.data; 


    const subHeaderComponent = useMemo(() => {

        return (
            <FilterComponent
                {...props}
                canSearch={props.canSearch}
                needDate={props.needDate}
            />
        );
    });
    return  <MDBox width="100%" p={3}><DataTable
        columns={props.columns}
        striped
        highlightOnHover
        paginationPerPage={props.entriesPerPage || 5}
        paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
        data={props.rows}
        pagination={props.data?.length > 5}
        subHeader
        subHeaderComponent={subHeaderComponent}
        subHeaderAlign="left"
        paginationComponentOptions={{
            rowsPerPageText:"Nmbre de lignes"
        }}
    /></MDBox>
}



export default FinalFilter;
