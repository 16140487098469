export default class xhr {
  constructor(url, masterKey) {
    this.url = url;
    this.masterKey = masterKey;
  }

  setCallback(callback, failureCallback) {
    this.xhttp = new XMLHttpRequest();
    var _self = this;
    this.xhttp.onreadystatechange = function () {
      if (_self.xhttp.readyState == 4) {
        if (_self.xhttp.status >= 200 && _self.xhttp.status <= 299) {
          callback(_self.xhttp.responseText);
        } else {
          failureCallback(_self.xhttp.responseText);
        }
      }
    };
  }

  setMasterkey(token) {
    this.masterkey = token;
  }

  getMasterkey() {
    return this.masterkey;
  }

  getWhatsapp() {
    const that = this;
    return new Promise((res, rej) => {
      that.setCallback(
        function (data) {
          res(data);
        },
        function (error) {
          rej(error);
        }
      );
      that.GET("/gestion/whatstart");
    });
  }

  getWhatsappToken() {
    const that = this;
    return new Promise((res, rej) => {
      that.setCallback(
        function (data) {
          res(data);
        },
        function (error) {
          rej(error);
        }
      );
      that.GET("/gestion/coolendy_status");
    });
  }

  /**
   * userLogin
   * @param {string} phone
   * @param {string} password
   * @returns Promise
   */

  userLogin(phone, password) {
    const that = this;
    return new Promise((res, rej) => {
      that.setCallback(
        function (data) {
          res(data);
        },
        function (error) {
          rej(error);
        }
      );
      that.POST("/login", { phone, password });
    });
  }

  /**
   * getMe: get a user logetIn
   * @returns Promise
   */
  getMe() {
    const that = this;
    return new Promise((res, rej) => {
      that.setCallback(
        function (data) {
          res(data);
        },
        function (error) {
          rej(error);
        }
      );
      that.GET("/user_me");
    });
  }

  /**
   * getUser: get a user by id
   * @param {number} userId
   * @returns {Promise}
   */

  getUser(userId) {
    const that = this;
    return new Promise((res, rej) => {
      that.setCallback(
        function (data) {
          res(data);
        },
        function (error) {
          rej(error);
        }
      );
      that.GET(`/user/${userId}`);
    });
  }

  getAgencies() {
    const that = this;
    return new Promise((res, rej) => {
      that.setCallback(
        function (data) {
          res(data);
        },
        function (error) {
          rej(error);
        }
      );
      that.GET(`/load_agencies`);
    });
  }

  setAgency(id, agence) {
    const that = this;
    return new Promise((res, rej) => {
      that.setCallback(
        function (data) {
          res(data);
        },
        function (error) {
          rej(error);
        }
      );
      that.PUT(`/user/${id}`, { agence });
    });
  }

  /**
   *
   * @param {number} page
   * @returns
   */

  listSms(page) {
    const that = this;
    return new Promise((res, rej) => {
      that.setCallback(
        function (data) {
          res(data);
        },
        function (error) {
          rej(error);
        }
      );
      that.GET(`/gestion/list_sms?page=${page}`);
    });
  }

  /**
   *
   * @param {number} page
   * @returns
   */

  listBouquets() {
    const that = this;
    return new Promise((res, rej) => {
      that.setCallback(
        function (data) {
          res(data);
        },
        function (error) {
          rej(error);
        }
      );
      that.GET(`/gestion/bouquets?page=0`);
    });
  }

  /**
   *
   * @param {number} page
   * @returns
   */

  listUsers(page) {
    const that = this;
    return new Promise((res, rej) => {
      that.setCallback(
        function (data) {
          res(data);
        },
        function (error) {
          rej(error);
        }
      );
      that.GET(`/users?page=${page}`);
    });
  }

  /**
   *
   * @param {number} page
   * @returns
   */

  listProviders(page) {
    const that = this;
    return new Promise((res, rej) => {
      that.setCallback(
        function (data) {
          res(data);
        },
        function (error) {
          rej(error);
        }
      );
      that.GET(`/gestion/providers?page=${page}`);
    });
  }

  /**
   *
   * @param {number} page
   * @returns
   */

  listAbonnement(page, nbr) {
    const that = this;
    return new Promise((res, rej) => {
      that.setCallback(
        function (data) {
          res(data);
        },
        function (error) {
          rej(error);
        }
      );
      that.GET(`/gestion/abonnements?page=${page}&nbr=${nbr || 20}`);
    });
  }

  /**
   *
   * @returns
   */
  loadAbonnes() {
    const that = this;
    return new Promise((res, rej) => {
      that.setCallback(
        function (data) {
          res(data);
        },
        function (error) {
          rej(error);
        }
      );
      that.GET(`/gestion/abon_list`);
    });
  }

  getNode(url) {
    const that = this;
    return new Promise((res, rej) => {
      that.setCallback(
        function (data) {
          res(data);
        },
        function (error) {
          rej(error);
        }
      );
      that.GET(url);
    });
  }

  addNode(d, url) {
    const that = this;
    return new Promise((res, rej) => {
      that.setCallback(
        function (data) {
          res(data);
        },
        function (error) {
          rej(error);
        }
      );
      that.POST(url, d);
    });
  }

  loadAgences() {
    const that = this;
    return new Promise((res, rej) => {
      that.setCallback(
        function (data) {
          res(data);
        },
        function (error) {
          rej(error);
        }
      );
      that.GET(`/load_agencies`);
    });
  }

  /**
   * @param {number} nbr
   * @param {number} page
   * @returns
   */
  loadClients(page, nbr) {
    const that = this;
    return new Promise((res, rej) => {
      that.setCallback(
        function (data) {
          res(data);
        },
        function (error) {
          rej(error);
        }
      );
      that.GET(`/gestion/clients?page=${page}&nbr=${nbr || 20}`);
    });
  }
  /**
   *
   * @param {string} path
   * @param {Object} seed
   */

  POST(path, seed) {
    this.xhttp.open("POST", this.url + path, true);
    if (this.masterKey) this.xhttp.setRequestHeader("x-access-token", this.masterKey);
    this.xhttp.setRequestHeader("Content-type", "application/json");
    this.xhttp.send(JSON.stringify(seed));
  }

  GET(path) {
    this.xhttp.open("GET", this.url + path, true);
    if (this.masterKey) this.xhttp.setRequestHeader("x-access-token", this.masterKey);
    this.xhttp.setRequestHeader("Content-type", "application/json");
    this.xhttp.send();
  }

  PUT(path, seed) {
    this.xhttp.open("PUT", this.url + path, true);
    if (this.masterKey) this.xhttp.setRequestHeader("x-access-token", this.masterKey);
    this.xhttp.setRequestHeader("Content-type", "application/json");
    this.xhttp.send(JSON.stringify(seed));
  }
}
