/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import "./sign.css";

import xhr from "../../../context/requests";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Add, Cancel, Edit, Sync } from "@mui/icons-material";
import { useMaterialUIController, login, setSession, getSession, setBouquet } from "context";
import MDSnackbar from "components/MDSnackbar";
import moment from "moment";
import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DatePicker from "react-date-picker";
import { lab } from "chroma-js";
import { setLayout } from "context";
import DataTable from "examples/Tables/DataTable";
import { useSms } from "layouts/tables/data/authorsTableData";
import { Job } from "layouts/tables/data/authorsTableData";
import PaymentForm from "layouts/billing/components/PaymentForm";
import FinalFilter from "layouts/searchcomponent";
const base = "https://gestion.mbitransfert.com";

function Basic() {
  const [controller, dispatch] = useMaterialUIController();
  const errorMessages = {
    bad_password_error: "Erreur de mot de passe",
    no_user_error: "Utilisateur inconnu",
  };
  const [rememberMe, setRememberMe] = useState(false);
  const [loginIn, setLoginIn] = useState(false);

  const [user, setUser] = useState({ phone: "", password: "" });
  const [message, setMessage] = useState({ color: "info", icon: "info", open: false, message: "" });

  const renderMessage = (message, color, icon) =>
    setMessage({
      message,
      color,
      icon,
      open: true,
    });

  const closeInfoSB = () =>
    setMessage({
      ...message,
      open: false,
    });

  const handleChanges = (e) => {
    //console.log(e.target.value,e.target.name)
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const connect = async () => {
    const { phone, password } = user;
    setLoginIn(true);
    try {
      let u = await login(phone, password);
      if (typeof u === "string") u = JSON.parse(u);
      if (u.error) throw Error(u.error);
      setLoginIn(false);
      setSession(u);
      window.location.replace("/v1/")
      //getSession(dispatch);
    } catch (e) {
      console.log(e.message);
      renderMessage(errorMessages[e.message] || "Erreur reseau", "warning", "info");
      setLoginIn(false);
    }
  };

  useEffect(() => {
    getSession(dispatch);
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <>
      {controller.session && <Navigate to={"/abonnements"} />}
      {!controller.session && (
        <BasicLayout image={bgImage}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Mobigestion
              </MDTypography>
              <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
                Connexion
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <MDInput
                    type="tel"
                    label="Telephone"
                    name="phone"
                    onChange={handleChanges}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    name="password"
                    onChange={handleChanges}
                    label="Mot de passe"
                    fullWidth
                  />
                </MDBox>
                <MDBox display="flex" alignItems="center" ml={-1}>
                  <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetRememberMe}
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Remember me
                  </MDTypography>
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    disabled={loginIn || controller.loading}
                    onClick={connect}
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    Se connecter
                    {loginIn && <Sync className="spin" size={32} />}
                  </MDButton>
                </MDBox>
                {/*   <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
              </MDBox>
            </MDBox>
          </Card>
          <MDSnackbar
            color={message.color}
            icon={message.icon}
            title="Mobigestion"
            content={message.message}
            dateTime="Maintenant"
            open={message.open}
            bgWhite={true}
            onClose={closeInfoSB}
            close={closeInfoSB}
          />
        </BasicLayout>
      )}
    </>
  );
}
export function AddNode() {
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const errorMessages = {
    bad_password_error: "Erreur de mot de passe",
    no_user_error: "Utilisateur inconnu",
  };
  const [rememberMe, setRememberMe] = useState(false);
  const [loginIn, setLoginIn] = useState(false);
  const [date2, setDate2] = useState(moment().add(30, "d").format("yyyy-MM-DD"));
  const [date1, setDate1] = useState(moment().format("yyyy-MM-DD"));
  const [client, setClient] = useState({ label: "" });
  const [sms, setSms] = useState({ label: "" });
  const [provider, setProvider] = useState({ label: "" });
  const [bouquet, setBouquet] = useState({ label: "" });
  const [user, setAbon] = useState(controller.abonnement);
  const [message, setMessage] = useState({ color: "info", icon: "info", open: false, message: "" });

  const renderMessage = (message, color, icon) =>
    setMessage({
      message,
      color,
      icon,
      open: true,
    });

  const closeInfoSB = () =>
    setMessage({
      ...message,
      open: false,
    });

  useEffect(() => {
    if (controller.abonnement?.nom) {
      // handleChanges({ target: { name: "client_id", value: controller.abonnement.client_id } })
      // handleChanges({ target: { name: "provider_id", value: controller.abonnement.fournisseur } })
      // handleChanges({ target: { name: "bouquet_id", value: controller.abonnement.bouquet } })
      setClient({
        ...controller.clientsDb.filter((e) => e.id == Number(controller.abonnement.client_id))[0],
        label: controller.abonnement.client,
        client_id: controller.abonnement.client_id,
      });
      setBouquet({
        ...controller.bouquets.filter((e) => e.id === Number(controller.abonnement.bouquet_id))[0],
        label: controller.abonnement.bouquet,
      });
      setProvider({
        ...controller.nbabon.filter((e) => e.id === Number(controller.abonnement.provider_id))[0],
        label: controller.abonnement.fournisseur,
      });
      const { ...rest } = controller.abonnement;
      setAbon(rest);
      setDate1(moment(controller.abonnement?.date_edition).format("YYYY-MM-DD"))
      setDate2(moment(controller.abonnement?.date_renouvellement).format("YYYY-MM-DD"))
      //const labon = controller.abonnementso.filter(e=>e.id==Number(nid))
      // dispatch({type:"ABONNEMENT",value:labon[0]})
    }
  }, [controller.abonnement]);

  useEffect(() => {
    if (user?.phone?.indexOf("/") > 0) {
      user?.phone
        .split("/")
        .map((e, i) => handleChanges({ target: { name: `phones[${i}]`, value: "" } }));
    }
  }, [user?.phone]);
  /* 
  useEffect(()=>{
    setAbon(controller.abonnement)
    Object.keys(controller.abonnement).map(e=>handleChanges({ target: { name: Object.keys(e)[0], value: Object.values(e)[0] } }))
    
  },[controller.abonnement]) */

  const handleChanges = (e) => {
    console.log(e.target.value, e.target.name);
    setAbon({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const connect = async () => {
    let httReq = new xhr("https://gestion.mobitransfert.com", controller.session.data[0].token);
    // return console.log(user)
    setLoginIn(true);
    try {
      let u = await httReq.addNode(
        user,
        `/gestion/add_abon` + (user.smsid ? `?smsid=${user.smsid}` : "")
      );
      if (typeof u === "string") u = JSON.parse(u);
      //console.log(u);
      if (u.error) throw Error(u.error);
      u = u.data;
      setLoginIn(false);
      let newAb = controller.abonnementso;
      if (u.insertId) {
        user.id = u.insertId;
        user.fournisseur = provider?.label;
        user.bouquet = bouquet?.label;
        user.client = client?.label;
        user.phone = client.phone;
        newAb.unshift(user);
        dispatch({ type: "ABONNEMENTSO", value: newAb });
        dispatch({ type: "ABONNEMENTS", value: newAb });
      } else {
        const ind = newAb.findIndex((e, i) => e.id == Number(user.id));
        newAb[ind] = user;
        dispatch({ type: "ABONNEMENTSO", value: newAb });
        dispatch({ type: "ABONNEMENTS", value: newAb });
        dispatch({
          type: "ABONNEMENT",
          value: {
            nom: "",
            client_id: "",
            provider_id: "",
            bouquet_id: "",
            date_edition: moment().format("yyyy-MM-DD"),
            date_renouvellement: moment().add(30, "d").format("yyyy-MM-DD"),
            statut: "yes",
            numero_abonnement: "",
          },
        });
      }
      navigate("/abonnements");
    } catch (e) {
      //console.log(e.message);
      renderMessage(
        errorMessages[e.message] || e.message || e || "Erreur reseau",
        "warning",
        "info"
      );
      setLoginIn(false);
    }
  };

  useEffect(() => {
    getSession(dispatch);
    return () =>
      dispatch({
        type: "ABONNEMENT",
        value: {
          nom: "",
          client_id: "",
          provider_id: "",
          bouquet_id: "",
          date_edition: moment().format("YYYY-MM-DD"),
          date_renouvellement: moment().add(30, "d").format("YYYY-MM-DD"),
          statut: "yes",
          numero_abonnement: "",
        },
      });
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Abonnements
                </MDTypography>
                <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
                  {user.id ? `Modifier l'abonnement ${user.nom}` : "Ajouter un abonnement"}
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <Grid container spacing={6} direction={"row"}>
                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="text"
                          required
                          label="Numero de l'abonnement"
                          value={user.numero_abonnement}
                          variant="filled"
                          name="numero_abonnement"
                          onChange={handleChanges}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox mb={2}>
                    <Grid container spacing={6} direction={"row"}>
                      <Grid item xs={12} md={6}>
                        <MDInput
                          type="text"
                          required
                          label="Nom de l'abonnement"
                          variant="filled"
                          value={user.nom}
                          name="nom"
                          onChange={handleChanges}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Autocomplete
                          id="free-solo-demo"
                          name="client_id"
                          value={client}
                          onChange={(event, newValue) => {
                            setClient(newValue || { label: "" });
                            console.clear();
                            handleChanges({ target: { name: "client_id", value: newValue?.id } });
                          }}
                          options={controller.clientsDb.map((e) => ({
                            ...e,
                            label: e.nom,
                            key: e.id,
                          }))}
                          renderInput={(params) => (
                            <TextField {...params} variant="filled" label="Client a abonner" />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox mb={2}>
                    <Grid container spacing={6} direction={"row"}>
                      <Grid item xs={12} md={6}>
                        {user?.phone && (
                          <MDTypography fontWeight="regular" color="text">
                            Numéro as notifier
                          </MDTypography>
                        )}
                        <FormGroup
                          sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
                        >
                          {user?.phone?.split("/")?.map((e, i) => {
                            return (
                              <FormControlLabel
                                key={e.trim()}
                                control={
                                  <Checkbox
                                    onChange={(event, checked) => {
                                      if (checked) {
                                        handleChanges({
                                          target: { name: `phones[${i}]`, value: e.trim() },
                                        });
                                      } else {
                                        handleChanges({
                                          target: { name: `phones[${i}]`, value: "" },
                                        });
                                      }
                                    }}
                                  />
                                }
                                label={e.trim()}
                              />
                            );
                          })}
                        </FormGroup>
                      </Grid>
                      {user.id && (
                        <Grid item xs={12} md={6}>
                          <Autocomplete
                            value={sms}
                            onChange={(event, newValue) => {
                              setSms(newValue);
                              console.clear();
                              handleChanges({
                                target: { name: "smsid", value: newValue?.id + "" },
                              });
                            }}
                            options={controller.sms.map((e) => ({
                              ...e,
                              label: e.body,
                              key: e.id,
                            }))}
                            renderInput={(params) => (
                              <TextField {...params} variant="filled" label="Choisir un sms" />
                            )}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </MDBox>
                  <MDBox mb={2}>
                    <Grid container spacing={6} direction={"row"}>
                      <Grid item xs={12} md={6}>
                        <Autocomplete
                          value={provider}
                          onChange={(event, newValue) => {
                            setProvider(newValue);
                            console.clear();
                            handleChanges({ target: { name: "provider_id", value: newValue?.id } });
                          }}
                          options={controller.nbabon.map((e) => ({
                            ...e,
                            label: e.nom,
                            key: e.id,
                          }))}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              label="Choisir un fournisseur"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Autocomplete
                          value={bouquet}
                          onChange={(event, newValue) => {
                            setBouquet(newValue);
                            console.clear();
                            handleChanges({ target: { name: "bouquet_id", value: newValue?.id } });
                          }}
                          options={controller.bouquets
                            .filter((e) => e.provider_id === provider?.id)
                            .map((e) => ({ ...e, label: e.nom, key: e.id }))}
                          renderInput={(params) => (
                            <TextField {...params} variant="filled" label="Choisir un bouquet" />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox mb={2}>
                    <Grid container spacing={6} direction={"row"}>
                      <Grid
                        item
                        xs={12}
                        left
                        md={6}
                        sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        flexDirection={"column"}
                      >
                        <TextField
                          type="date"
                          onChange={(e) => {
                            setDate1(moment(e.target.value).format("YYYY-MM-DD"))
                            handleChanges({
                              target: { name: "date_edition", value: moment(e.target.value).format("YYYY-MM-DD") },
                            })
                          }
                          }
                          fullWidth
                          size="medium"
                          variant="filled"
                          value={date1}
                          label="date d'activation"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        left
                        sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        flexDirection={"column"}
                      >
                        <TextField
                          type="date"
                          size="medium"
                          onChange={(e) => {
                            setDate2(moment(e.target.value).format("YYYY-MM-DD"))
                            handleChanges({
                              target: { name: "date_renouvellement", value: moment(e.target.value).format("YYYY-MM-DD") },
                            })
                          }
                          }
                          fullWidth
                          value={date2}
                          variant="filled"
                          label="Date de renouvellement"
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                  {/*                   <MDBox display="flex" alignItems="center" ml={-1}>
                    <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      Actif
                    </MDTypography>
                  </MDBox> */}
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      disabled={loginIn || controller.loading}
                      onClick={connect}
                      variant="gradient"
                      color="info"
                      fullWidth
                    >
                      Valider
                      {loginIn && <Sync className="spin" size={32} />}
                    </MDButton>
                  </MDBox>
                  {/*   <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
                </MDBox>
              </MDBox>
            </Card>
            <MDSnackbar
              color={message.color}
              icon={message.icon}
              title="Mobigestion"
              content={message.message}
              dateTime="Maintenant"
              open={message.open}
              bgWhite={true}
              onClose={closeInfoSB}
              close={closeInfoSB}
            />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export function AddPerson() {
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const errorMessages = {
    bad_password_error: "Erreur de mot de passe",
    no_user_error: "Utilisateur inconnu",
  };
  const [rememberMe, setRememberMe] = useState(false);
  const [loginIn, setLoginIn] = useState(false);
  const [user, setAbon] = useState(controller.client);
  const [message, setMessage] = useState({ color: "info", icon: "info", open: false, message: "" });

  const renderMessage = (message, color, icon) =>
    setMessage({
      message,
      color,
      icon,
      open: true,
    });

  const closeInfoSB = () =>
    setMessage({
      ...message,
      open: false,
    });

  useEffect(() => {
    if (controller.client) {
      setAbon(controller.client);
    }
  }, [controller.client]);

  const handleChanges = (e) => {
   // console.log(e.target.value, e.target.name);
    setAbon({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const connect = async () => {
    let httReq = new xhr("https://gestion.mobitransfert.com", controller.session.data[0].token);
    setLoginIn(true);
    try {
      let u = await httReq.addNode(user, `/gestion/add_client`);
      if (typeof u === "string") u = JSON.parse(u);
      //console.log(u);
      if (u.error) throw Error(u.error);
      u = u.data;
      setLoginIn(false);
      let newAb = controller.clientsDb;
      if (u.insertId) {
        user.id = u.insertId;
        user.nbabo = 0;
        newAb.unshift(user);
        dispatch({ type: "SHOPCLIENTS", value: newAb });
      } else {
        const ind = newAb.findIndex((e, i) => e.id == Number(user.id));
        newAb[ind] = user;
        dispatch({ type: "SHOPCLIENTS", value: newAb });
        dispatch({
          type: "CLIENT",
          value: { nom: "", email: "", phone: "" },
        });
      }
      navigate("/clients");
    } catch (e) {
     // console.log(e.message);
      renderMessage(
        errorMessages[e.message] || e.message || e || "Erreur reseau",
        "warning",
        "info"
      );
      setLoginIn(false);
    }
  };

  useEffect(() => {
    getSession(dispatch);
    return () =>
      dispatch({
        type: "CLIENT",
        value: { nom: "", email: "", phone: "" },
      });
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Clients
                </MDTypography>
                <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
                  {user.id ? `Modifier l'abonnement ${user.nom}` : "Ajouter un client"}
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      required
                      label="Nom"
                      value={user.nom}
                      variant="filled"
                      name="nom"
                      onChange={handleChanges}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      required
                      label="Localisation"
                      value={user.email}
                      variant="filled"
                      name="email"
                      onChange={handleChanges}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      required
                      label="Telephone(s)"
                      helperText="Separer les numeros par / "
                      value={user.phone}
                      variant="filled"
                      name="phone"
                      onChange={handleChanges}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      disabled={loginIn || controller.loading}
                      onClick={connect}
                      variant="gradient"
                      color="info"
                      fullWidth
                    >
                      Enregistrer
                      {loginIn && <Sync className="spin" size={32} />}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
            <MDSnackbar
              color={message.color}
              icon={message.icon}
              title="Mobigestion"
              content={message.message}
              dateTime="Maintenant"
              open={message.open}
              bgWhite={true}
              onClose={closeInfoSB}
              close={closeInfoSB}
            />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export function AddSms() {
  const [controller, dispatch] = useMaterialUIController();
  const [clients, SetClients] = useState([]);
 
  const { rows, columns } = {
    columns: [
      { Header: "Contenu",name: "Contenu", accessor: "body", selector: (row) => row.body,width: "55%", align: "left" },
      // { Header: "Categorie", accessor: "categori", align: "left" },
      { Header: "Modifier",name: "Modifier", accessor: "edit",selector: (row) => row.edit, align: "center" },
    ],
    rows: clients.map((e) => ({
      id: e.id,
      body: <MDTypography component="div" variant="body"
        color="text"
        fontWeight="normal">{e.body}</MDTypography>,
      edit: (
        <MDTypography
          onClick={(event) => {
            dispatch({ type: "SMSI", value: e });
            setEditMode(true)
            // navigate(`/editabon`);
          }}
          component="a"
          href={`#`}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          <Edit />
        </MDTypography>
      ),
    })),
  };
  const errorMessages = {
    bad_password_error: "Erreur de mot de passe",
    no_user_error: "Utilisateur inconnu",
  };
  const [rememberMe, setRememberMe] = useState(false);
  const [loginIn, setLoginIn] = useState(false);
  const [user, setAbon] = useState(controller.smsi);
  const [message, setMessage] = useState({ color: "info", icon: "info", open: false, message: "" });
  const renderMessage = (message, color, icon) =>
    setMessage({
      message,
      color,
      icon,
      open: true,
    });

  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    if (editMode) {
      document.scrollingElement.scrollTop = 0;
    }
  }, [editMode])

  const closeInfoSB = () =>
    setMessage({
      ...message,
      open: false,
    });

  useEffect(() => {
    if (controller.client) {
      setAbon(controller.smsi);
    }
  }, [controller.smsi]);

  useEffect(() => {
    let updateClients = () => {
      if (controller.sms) {
        SetClients(controller.sms);
      }
    };
    updateClients();
    return () => {
      updateClients = () => { };
    };
  }, [controller.sms]);

  const handleChanges = (e) => {
   // console.log(e.target.value, e.target.name);
    setAbon({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const search = (value) => {
    var regexObj = new RegExp(value + "", "i");
    if (value)
      return SetClients(
        clients.filter((e) => {
          return regexObj.test(e.body);
        })
      );
    return SetClients(controller.sms);
  };

  const connect = async () => {
    let httReq = new xhr("https://gestion.mobitransfert.com", controller.session.data[0].token);
    setLoginIn(true);
    try {
      let u = await httReq.addNode(user, `/gestion/add_sms`);
      if (typeof u === "string") u = JSON.parse(u);
      //console.log(u);
      if (u.error) throw Error(u.error);
      u = u.data;
      setLoginIn(false);
      let newAb = controller.sms;
      if (u.insertId) {
        user.id = u.insertId;
        user.nbabo = 0;
        newAb.unshift(user);
        dispatch({ type: "SMS", value: newAb });
        dispatch({
          type: "SMSI",
          value: { body: "", categori: "" },
        });
        setEditMode(false)
      } else {
        const ind = newAb.findIndex((e, i) => e.id == Number(user.id));
        newAb[ind] = user;
        dispatch({ type: "SMS", value: newAb });
        dispatch({
          type: "SMSI",
          value: { body: "", categori: "" },
        });
        setEditMode(false)
      }
      //navigate("/sms");
    } catch (e) {
      //console.log(e.message);
      renderMessage(
        errorMessages[e.message] || e.message || e || "Erreur reseau",
        "warning",
        "info"
      );
      setLoginIn(false);
    }
  };

  useEffect(() => {
    getSession(dispatch);
    return () =>
      dispatch({
        type: "SMSI",
        value: { body: "", categori: "" },
      });
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card>
              {editMode && <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  {!user.id ? "Ajouter" : "Modifier"} un SMS
                </MDTypography>
                <MDButton
                  component="a"
                  onClick={() => setEditMode(false)}
                  href="#"
                  variant="gradient"
                  color="dark"
                >
                  <Cancel />
                  &nbsp;Annuler
                </MDButton>
              </MDBox>}
              {editMode && <MDBox pt={0} mb={5} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="categori">Categorie de SMS</InputLabel>
                      <Select
                        labelId="categori"
                        id="categori"
                        name="categori"
                        value={user.categori}
                        onChange={handleChanges}
                        required
                        label="Categorie de SMS"
                      >
                        <MenuItem value="">
                          <em>-------</em>
                        </MenuItem>
                        <MenuItem value={"activation"}>Activation</MenuItem>
                        <MenuItem value={"relance"}>Relance</MenuItem>
                        <MenuItem value={"notification"}>Notification</MenuItem>
                      </Select>
                    </FormControl>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      required
                      label="Contenu du SMS"
                      multiline
                      value={user.body}
                      variant="filled"
                      name="body"
                      onChange={handleChanges}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      disabled={loginIn || controller.loading}
                      onClick={connect}
                      variant="gradient"
                      color="info"
                      fullWidth
                    >
                      Enregistrer
                      {loginIn && <Sync className="spin" size={32} />}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>}
              {!editMode && <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  SMS
                </MDTypography>
                <MDButton
                  component="a"
                  onClick={() => setEditMode(true)}
                  href="#"
                  variant="gradient"
                  color="dark"
                >
                  <Add />
                  &nbsp;Ajouter
                </MDButton>
              </MDBox>}
              {!editMode && <MDBox pt={0} pb={3} px={3} sx={{ maxWidth: "100%" }}>
              {controller.sms.length > 0 &&<FinalFilter data={rows}  columns={columns} rows={rows}
                        searchText="Rechercher sms"
                        entriesPerPage={20}
                        setFilter={search}
                        canSearch
                       />}
                {/* {controller.sms.length > 0 && (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    canSearch
                    sendDate={console.log}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    searchText="Rechercher sms"
                    setFilter={search}
                    logKey={(e) => {
                      console.log(clients[e])
                      dispatch({ type: "SMSI", value: clients[e] });
                      setEditMode(true)
                    }}
                  />
                )} */}
              </MDBox>}

            </Card>
            <MDSnackbar
              color={message.color}
              icon={message.icon}
              title="Mobigestion"
              content={message.message}
              dateTime="Maintenant"
              open={message.open}
              bgWhite={true}
              onClose={closeInfoSB}
              close={closeInfoSB}
            />
          </Grid>
        </Grid>
      </MDBox>
    
    </DashboardLayout>
  );
}
export function AddBouquet() {
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const errorMessages = {
    bad_password_error: "Erreur de mot de passe",
    no_user_error: "Utilisateur inconnu",
  };
  const [rememberMe, setRememberMe] = useState(false);
  const [loginIn, setLoginIn] = useState(false);
  const [user, setAbon] = useState(controller.bouquet);
  const [provider, setProvider] = useState({ label: user.fournisseur || "" });
  const [message, setMessage] = useState({ color: "info", icon: "info", open: false, message: "" });

  const renderMessage = (message, color, icon) =>
    setMessage({
      message,
      color,
      icon,
      open: true,
    });

  const closeInfoSB = () =>
    setMessage({
      ...message,
      open: false,
    });

  useEffect(() => {
    if (controller.bouquet) {
      setAbon(controller.bouquet);
    }
  }, [controller.bouquet]);

  const handleChanges = (e) => {
    console.log(e.target.value, e.target.name);
    setAbon({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const connect = async () => {
    let httReq = new xhr("https://gestion.mobitransfert.com", controller.session.data[0].token);
    setLoginIn(true);
    try {
      if (!user.provider_id) throw Error("Choisir un fournisseur");
      if (!user.nom) throw Error("Le Nom du bouquet est obligatoire");
      if (!user.prix) throw Error("Le prix du bouquet est obligatoire");
      if (!user.duration) throw Error("La durée du bouquet est obligatoire");
      let u = await httReq.addNode(user, `/gestion/add_bouquet`);
      if (typeof u === "string") u = JSON.parse(u);
      console.log(u);
      if (u.error) throw Error(u.error);
      u = u.data;
      setLoginIn(false);
      let newAb = controller.bouquets;
      if (u.insertId) {
        user.id = u.insertId;
        user.fournisseur = provider.label;
        newAb.unshift(user);
        dispatch({ type: "BOUQUETS", value: newAb });
      } else {
        const ind = newAb.findIndex((e, i) => e.id == Number(user.id));
        newAb[ind] = user;
        dispatch({ type: "BOUQUETS", value: newAb });
        dispatch({
          type: "BOUQUET",
          value: { nom: "", provider_id: "", prix: "", duration: "30" },
        });
      }
      navigate("/bouquets");
    } catch (e) {
      console.log(e.message);
      renderMessage(
        errorMessages[e.message] || e.message || e || "Erreur reseau",
        "warning",
        "info"
      );
      setLoginIn(false);
    }
  };

  useEffect(() => {
    getSession(dispatch);
    return () =>
      dispatch({
        type: "BOUQUET",
        value: { nom: "", provider_id: "", prix: "", duration: "30" },
      });
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Bouquets
                </MDTypography>
                <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
                  {user.id ? `Modifier le bouquet ${user.nom}` : "Ajouter un bouquet"}
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <Autocomplete
                      id="free-solo-demo"
                      name="provider_id"
                      value={{ ...provider, label: provider?.label || "" }}
                      onChange={(event, newValue) => {
                        setProvider({ ...newValue, label: newValue?.nom });
                        console.clear();
                        handleChanges({ target: { name: "provider_id", value: newValue?.id } });
                      }}
                      options={controller.nbabon.map((e) => ({ ...e, label: e.nom, key: e.id }))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          label="Selectionner un fournisseur"
                        />
                      )}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      required
                      label="Nom du bouquet"
                      value={user.nom}
                      variant="filled"
                      name="nom"
                      onChange={handleChanges}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="number"
                      required
                      label="Prix du bouquet"
                      value={user.prix}
                      variant="filled"
                      name="prix"
                      onChange={handleChanges}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="number"
                      required
                      label="Durée du bouquet"
                      helperText="Périodicité de renouvellement"
                      value={user.duration}
                      variant="filled"
                      name="duration"
                      onChange={handleChanges}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      disabled={loginIn || controller.loading}
                      onClick={connect}
                      variant="gradient"
                      color="info"
                      fullWidth
                    >
                      Enregistrer
                      {loginIn && <Sync className="spin" size={32} />}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
            <MDSnackbar
              color={message.color}
              icon={message.icon}
              title="Mobigestion"
              content={message.message}
              dateTime="Maintenant"
              open={message.open}
              bgWhite={true}
              onClose={closeInfoSB}
              close={closeInfoSB}
            />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Basic;
