/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import Clients from "layouts/tables";
import { AccountBox, CardGiftcard, Person2, Sms, WhatsApp } from "@mui/icons-material";
import { Bouquets } from "layouts/tables";
import { Employees } from "layouts/tables";
import { AddNode } from "layouts/authentication/sign-in";
import { AddPerson } from "layouts/authentication/sign-in";
import { AddBouquet } from "layouts/authentication/sign-in";
import WhatsappN from "layouts/notifications/whatsapp";
import { AddSms } from "layouts/authentication/sign-in";

const routes = [
  {
    type: "collapse",
    name: "Tableau de bord",
    key: "abonnements",
    visible: true,
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/abonnements",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    visible: true,
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/clients",
    component: <Clients />,
  },
  {
    type: "collapse",
    name: "Bouquets",
    key: "bouquets",
    visible: true,
    icon: <CardGiftcard />,
    route: "/bouquets",
    component: <Bouquets />,
  },
  {
    type: "collapse",
    name: "Ajouter un Bouquets",
    key: "addbouquet",
    visible: false,
    icon: <CardGiftcard />,
    route: "/addbouquet",
    component: <AddBouquet />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    visible: false,
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "Personnel",
    key: "personnel",
    visible: true,
    icon: <AccountBox />,
    route: "/personnel",
    component: <Employees />,
  },
  {
    type: "collapse",
    name: "Ajouter un abonnement",
    key: "abonnements",
    visible: false,
    icon: <AccountBox />,
    route: "/addAbon",
    component: <AddNode />,
  },
  {
    type: "collapse",
    name: "Gestion SMS",
    key: "sms",
    visible: true,
    icon: <Sms />,
    route: "/sms",
    component: <AddSms />,
  },
  {
    type: "collapse",
    name: "Modifier un abonnement",
    key: "editAbon",
    visible: false,
    icon: <AccountBox />,
    route: "/editabon",
    component: <AddNode />,
  },
  {
    type: "collapse",
    name: "Ajouter un client",
    key: "addClient",
    visible: false,
    icon: <Person2 />,
    route: "/addClient",
    component: <AddPerson />,
  },
  /*   {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    visible:true,
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  }, */
  {
    type: "collapse",
    name: "Notifications Whatsapp",
    key: "whatsapp",
    visible: true,
    icon: <WhatsApp />,
    route: "/whatsapp",
    component: <WhatsappN />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    visible: true,
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    visible: false,
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    visible: false,
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
];

export default routes;
