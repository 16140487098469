/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/
import xhr from "./requests";

import { createContext, useContext, useReducer, useMemo, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import moment from "moment";
import { placeholder } from "assets/base64";
import { done } from "assets/base64";
import { jwtDecode } from "jwt-decode";

// Material Dashboard 2 React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "SESSION": {
      return { ...state, session: action.value };
    }
    case "ABONNES": {
      return { ...state, nbabon: action.value };
    }
    case "CLIENTS": {
      return { ...state, clients: action.value };
    }
    case "SHOPCLIENTS": {
      return { ...state, clientsDb: action.value };
    }
    case "EMPLOYEES": {
      return { ...state, employers: action.value };
    }
    case "SMS": {
      return { ...state, sms: action.value };
    }
    case "SMSI": {
      return { ...state, smsi: action.value };
    }

    case "BOUQUETS": {
      return { ...state, bouquets: action.value };
    }
    case "ABONNEMENTS": {
      return { ...state, abonnements: action.value };
    }
    case "AGENCES": {
      return { ...state, agences: action.value };
    }
    case "ABONNEMENTSO": {
      return { ...state, abonnementso: action.value };
    }
    case "ABONNEMENT": {
      return { ...state, abonnement: action.value };
    }
    case "CLIENT": {
      return { ...state, client: action.value };
    }
    case "USER": {
      return { ...state, user: action.value };
    }
    case "BOUQUET": {
      return { ...state, bouquet: action.value };
    }
    case "WHATOKEN": {
      return { ...state, whatoken: action.value };
    }
    case "QRCODE": {
      return { ...state, qrcode: action.value };
    }
    case "PAGER": {
      return { ...state, pager: action.value };
    }
    case "PACKAGES": {
      return { ...state, packages: action.value || [] };
    }
    case "LOADING": {
      return { ...state, loading: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    baseurl: "https://gestion.mobitransfert.com/",
    darkMode: false,
    loading: false,
    session: null,
    whatoken: null,
    qrcode: placeholder,
    sms: [],
    packages: [],
    employers: [],
    pager:{},
    nbabon: [],
    agences: [],
    clients: {},
    user: {},
    bouquet: { nom: "", provider_id: "", prix: "", duration: "30" },
    smsi: { body: "", categori: "" },
    client: { nom: "", email: "", phone: "" },
    clientsDb: [],
    bouquets: [],
    abonnements: [],
    abonnementso: [],
    abonnement: {
      nom: "",
      client_id: "",
      provider_id: "",
      bouquet_id: "",
      date_edition: moment().format("YYYY-MM-DD"),
      date_renouvellement: moment().add(30, "d").format("YYYY-MM-DD"),
      statut: "yes",
      numero_abonnement: "",
    },
  };

  const [controller, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  const setNbabon = (value) => dispatch({ type: "ABONNES", value });
  const setSms = (value) => dispatch({ type: "SMS", value });
  const setBouquets = (value) => dispatch({ type: "BOUQUETS", value });
  const setAbonnes = (value) => dispatch({ type: "CLIENTS", value });
  const setAbonnements = (value) => dispatch({ type: "ABONNEMENTS", value });
  const setAbonnementso = (value) => dispatch({ type: "ABONNEMENTSO", value });

  const donwloadAbonnements = async (page, httpReq, arr, nbr) => {
    let newvals = JSON.parse(await httpReq.listAbonnement(page, nbr)).data;
    let oldval = arr;
    //console.log({ oldval, newvals })
    setAbonnementso(oldval.concat(newvals));
    return newvals;
  };

  const downloadClients = async (page, MobigestionRequest, arr, nbr) => {
    let newvals = JSON.parse(await MobigestionRequest.loadClients(page, nbr)).data;
    let oldval = arr;
    //console.log({ oldval, newvals })
    dispatch({ type: "SHOPCLIENTS", value: oldval.concat(newvals) });
    return newvals;
  };

  const downloadAll = async (arr, page, nbr, httpReq) => {
    const retv = [];
    await [
      await donwloadAbonnements(page, httpReq, arr[0], nbr),
      await downloadClients(page, httpReq, arr[1], nbr),
    ].reduce(async (prevv, curr, index) => {
      const resolvedPreviousValue = await prevv;
      let newvals = await curr;
      //console.log(JSON.parse(newvals).data)
      retv.push(newvals);
      return newvals;
    }, Promise.resolve());
    if (retv[1].length > nbr) dispatch({ type: "SHOPCLIENTS", value: arr[1].concat(retv[1]) });
    if (retv[0].length > nbr) {
      setAbonnementso(arr[0].concat(retv[0]));
      setAbonnements(arr[0].concat(retv[0]));
    }
    if (retv.filter((e) => e.length === nbr).length > 0) {
      setTimeout(
        () => downloadAll([arr[0].concat(retv[0]), arr[1].concat(retv[1])], page + 1, nbr, httpReq),
        1000
      );
    }
  };

  useEffect(() => {
    let load = async () => {
      if (controller.session && controller.abonnementso?.length === 0) {
        let retv = [];
        let httReq = new xhr("https://gestion.mobitransfert.com", controller.session.data[0].token);
        const expireIn = moment(jwtDecode(controller.session.data[0].token).exp * 1000).diff(moment(), "minutes")
        if (Number(expireIn) <= 0) {
          logout()
          getSession(dispatch);
          return
        }
        dispatch({ type: "LOADING", value: true });
        await [
          await httReq.loadAbonnes(),
          await httReq.listSms(0),
          await httReq.listProviders(0),
          await httReq.listAbonnement(0, 100),
          await httReq.loadClients(0, 100),
          await httReq.listBouquets(),
          await httReq.listUsers(0),
          await httReq.loadAgences(),
          await httReq.addNode({}, "/gestion/list_abonn")
        ].reduce(async (prevv, curr, index) => {
          const resolvedPreviousValue = await prevv;
          let newvals = await curr;
          try {
            retv.push(JSON.parse(newvals).data || JSON.parse(newvals));
          } catch (e) {
            // alert(e.message)
          }

          return newvals;
        }, Promise.resolve());
        setAbonnes(retv[0]);
        setSms(retv[1]);
        setNbabon(retv[2]);
        setAbonnements(retv[3]);
        setAbonnementso(retv[3]);
        setBouquets(retv[5]);
        checkCoolendy(httReq, dispatch)
        dispatch({ type: "SHOPCLIENTS", value: retv[4] });
        dispatch({ type: "EMPLOYEES", value: retv[6] });
        dispatch({ type: "AGENCES", value: retv[7] });
        dispatch({ type: "PACKAGES", value: retv[8] });
        dispatch({ type: "LOADING", value: false });
        setTimeout(() => downloadAll([retv[3], retv[4]], 1, 100, httReq), 1000);
      }
    };
    load();
  }, [controller.session]);

  useEffect(() => {
    if (controller.bouquets) {
      console.log({
        bouquet: controller.bouquets,
      });
    }
  }, [controller.bouquets]);
  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setSMS = (dispatch, value) => dispatch({ type: "SMSI", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setAbonnement = (dispatch, value) => dispatch({ type: "ABONNEMENT", value });
const setClient = (dispatch, value) => dispatch({ type: "CLIENT", value });
const setBouquet = (dispatch, value) => dispatch({ type: "BOUQUET", value });
const setQrcode = (dispatch, value) => dispatch({ type: "QRCODE", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setSession = (value) => localStorage.setItem("session", JSON.stringify(value));
const checkCoolendy = async (httpReq, dispatch) => {
  const whatoken = localStorage.getItem("whatoken")
  if (whatoken) {
    let expireIn = moment(jwtDecode(whatoken).exp * 1000).diff(moment(), "s")
    if (Number(expireIn) <= 2) {
      dispatch({ type: "WHATOKEN", value: null });
      localStorage.removeItem("whatoken")
      return
    }
    dispatch({ type: "WHATOKEN", value: whatoken });
    return;
  }
  let check = await httpReq.getWhatsappToken()
  if (typeof check === "string") check = JSON.parse(check);
  if (check.error) throw Error(check.error)
  if(check.data.length ==0) return
  const expireIn = moment(jwtDecode(check.data?.at(0)?.token).exp * 1000).diff(moment(), "s")
  if (Number(expireIn) <= 2) {
    dispatch({ type: "WHATOKEN", value: null });
    return
  }
  dispatch({ type: "WHATOKEN", value: check.data[0].token });
  localStorage.setItem("whatoken", check.data[0].token)
  return check.data;
}

const login = async (phone, password) => {
  const MobigestionRequest = new xhr("https://gestion.mobitransfert.com", null);
  return await MobigestionRequest.userLogin(phone, password);
};
const checkQr = (httpReq, dispatch) => {
  httpReq
    .getWhatsapp()
    .then((e) => {
      if (typeof e === "string") e = JSON.parse(e);
      //console.log(e);
      if (e.qr) {
        setQrcode(dispatch, e.qr);
        return;
      } else if (e.message && /Session found/.test(e.message)) {
        setQrcode(dispatch, done);
      }
    })
    .catch(console.log);
};

const logout = () => {
  localStorage.clear();
};
const getSession = (dispatch) => {
  var value = JSON.parse(localStorage.getItem("session"));
  dispatch({ type: "SESSION", value });
  return value;
};

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setSession,
  setAbonnement,
  checkCoolendy,
  login,
  checkQr,
  logout,
  setClient,
  setBouquet,
  getSession,
};
