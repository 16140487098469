import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { colors } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { useMaterialUIController } from 'context';
import xhr from 'context/requests';
import ConfirmWindow from 'layouts/notifications/confirm';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function RelanceWindow(props) {
    const [controller, dispatch] = useMaterialUIController()
    const [confirmOpened,openConfirm] = React.useState(false)
    const [relid,setRelance] = React.useState()

    const handleClose = () => {
        props.setOpen(false);
    };

    const beforeSend = ()=>{
        openConfirm(true)
    }

    React.useEffect(()=>{
        if(relid) {
            openConfirm(true)
            handleClose()
        }
    },[relid])

    React.useEffect(()=>{
        if(!confirmOpened) setRelance()
    },[confirmOpened])

    const sendRelance = async () => {
        openConfirm(false)
        let httReq = new xhr("https://gestion.mobitransfert.com", controller.session.data[0].token);
        dispatch({ type: "LOADING", value: true });
        //alert("started")
        await controller.abonnements.reduce(async (prevv, curr, index) => {
            const resolvedPreviousValue = await prevv;
            let newvals = await httReq.addNode(curr, "/gestion/smsnotify?smsid=" + relid?.id);
            //console.log(JSON.parse(newvals).data) 
            return newvals;
        }, Promise.resolve());
        dispatch({ type: "LOADING", value: false });
        handleClose()
    }

    return (
        <>
        <Dialog
            fullScreen
            open={props.open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', p: 0, paddingRight: "0 !important" }} >
                <MDBox
                    //variant="gradient"
                    bgColor="info"
                    //borderRadius="lg"
                    coloredShadow="info"
                    //mx={2}
                    //mt={-3}
                    width="100%"
                    py={2}
                    px={2}
                    mb={2}
                    color={"white"}
                    display={"flex"}
                    justifyContent={"space-arround"}
                    alignItems="center"
                >
                    <IconButton
                        edge="start"
                        color="white"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1, color: 'white !important' }} variant="h6" component="div">
                        Choisir un SMS
                    </Typography>

                </MDBox>
            </AppBar>
            <List sx={{ mx: 3 }} >
                {controller.sms.map(e =>
                (<>
                    <ListItem key={e.id} button={true} onClick={() => setRelance(e)} disabled={controller.loading}>
                        <ListItemText primary={e.body} secondary={e.type} />
                    </ListItem>
                    <Divider />
                </>)
                )}

            </List>
        </Dialog>
        <ConfirmWindow open={confirmOpened} setOpen={openConfirm} accept={sendRelance} text={relid?.body} question="Voulez vous envoyer cette relance?"/>
        </>
    );
}